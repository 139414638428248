import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: {
            "no": "No",
            "yes": "Yes",
            "ok": "Ok",
        }
    },
    es: {
        translation: {
            // Respuestas
            "no": "No",
            "yes": "Sí",
            "ok": "Aceptar", 
            "send": "Enviar",
            
            // Procesos
            "in_process": "Procesando...",
            "process_successfull": "Proceso exitoso.",
            "process_error": "Problemas en el proceso, intentelo nuevamente en unos minutos.",
            
            // Actualizar contraseña
            "fill_imputs_for_change_password": "Llene todos los campos para actualizar la contraseña.",
            "password_confirm_isnot_same": "La contraseña de confirmarción no es igual a la original.",

            // Modulo de usuario
            "user_create": "Crear usuario",
            "user_edit": "Actualizar usuario",
            "user_list": "Lista de usuarios",
            "token_remember": "Recordar token",      
            "password_update": "Actualizar contraseña",      
            "password": "Contraseña",      
            "password_confirm": "Contraseña de confirmación",      

            // Propiedades genericas
            "name": "Nombre",
            "user_name": "Nombre de usuario",
            "email": "Correo Electronico",
            "state": "Estado",
            "edit": "Editar",
            "create": "Crear",
            "new": "Nuevo",

            "active": "Activo",
            "actives": "Activo(s)",
            "inactive": "Inactivo",
            "inactives": "Inactivo(s)",
            "deleted": "Eliminado",
            "deleteds": "Eliminado(s)",

            "result_by": "resultado(s) de",
            "page": "Pagina",
            "records": "registro(s)",
            "record": "registro",
            
            

            "observation": "Observación",  
            "word_search": "Escriba una palabra para buscar",
            "save": "Guardar",
            "update": "Actualizar",
            "update_confirm": "¿Esta seguro que quiere actualizar?",            
            "created_at": "Fecha de creación",
            "created_by": "Usuario creador",
            "updated_at": "Fecha de actualización",
            "updated_by": "Usuario actualizador",
            "audit_information": "Información de auditoria",            
            
            // Otros
            "basic_information": "Información Basica",

            // Para validadores
            "is_required": "Es un dato requerido",
            "is_not_valid": "No es un dato valido",
            "should_be_at_least_": "Debe tener al menos ",
            "char": "caracteres",
            "password_not_marched": "Contraseña de confirmación no coincide con la contraseña ingresada",

        }
    }
};

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
  resources,
  lng: "es", 
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});