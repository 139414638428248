
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const HtmlSidebar = () => {
    // Predefiniendo variables
    const [menus, setMenus] = useState([])
    let url="/";

    // Invocador
    useEffect ( () => {
        getAllMenus()
    }, [])

    // Metodos
    const getAllMenus = async () => {
        
        const menusOut = [];

        menusOut.push({
            icon: 'bi bi-lightning-charge', 
            label: 'Nosotros', 
            url: '#divNosotros', 
            rows: []});

        menusOut.push({
            icon: 'bi bi-box-seam', 
            label: 'Productos', 
            url: '#divProductos', 
            rows: []});

        menusOut.push({
            icon: 'bi bi-cloud-lightning-rain', 
            label: 'Servicios', 
            url: '#divServicios', 
            rows: []});

        menusOut.push({
            icon: 'bi bi-chat-dots', 
            label: 'Contacto', 
            url: '#divContacto', 
            rows: []});

        setMenus(menusOut)
    }
    
    // VISTA
    return (
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasLeft" aria-labelledby="offcanvasLeftLabel" style={{'width':'200px'}}>
            <div class="offcanvas-header">
                <h5 id="offcanvasLeftLabel">
                    <div class="navbar-brand" style={{ "font-size": "18px" }} >
                        <strong>
                            <img src="img/brand/logo.png" width="28" alt="TecnoTrueno" />
                            TecnoTrueno 
                        </strong>
                    </div>
                </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <div class="list-group">
                    {menus.map((item,index) => (
                        <a href={item.url} className="list-group-item list-group-item-action border-0 border-top link-custom">
                            <i className={item.icon} ></i> {item.label}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}
