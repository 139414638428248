import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import ShowCursos from './components/ShowCursos'
import CreateCurso from './components/CreateCurso'
import EditCurso from './components/EditCurso'
import { HtmlSidebar } from './components/layouts/HtmlSidebar';
import { HtmlAsidebar } from './components/layouts/HtmlAsidebar';
import ListMenu from './components/menu/ListMenu';
import EditMenu from './components/menu/EditMenu';
import CreateMenu from './components/menu/CreateMenu';
import ListUser from './components/user/ListUser';
import EditUser from './components/user/EditUser';
import CreateUser from './components/user/CreateUser';
import Portal from './components/Portal';


function App() {
  return (    
    <div className="app-body">
      <HtmlSidebar/>      
      <HtmlAsidebar/>      
      <Portal />
      
    </div>
  );
}

export default App;
