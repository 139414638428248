import React from 'react'
import styled from "styled-components";
import { FaQuestionCircle, FaInfoCircle, FaRegClock, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const ModalByMANV = ({openFn,openModal}) => {
    // Propiedades
    const { t, setT } = useTranslation();
    
    // Variables
    var varRender;

    // Funciones
    const closeModal = () => {
        openFn({
            type: "",
            message: ""
        });        
    };

    // Seleccionando tipo de modal
    switch (openModal.type) {
        case 'confirm':
            varRender = 
                <>
                {
                    <Overlay>
                        <CentradoModal>
                            <ContenedorModal>   
                                <TitleModal>
                                    <FaQuestionCircle />
                                </TitleModal>
                                <BodyModal>
                                    {openModal.message}
                                </BodyModal>  
                                <FooterModal>                            
                                    <button className="btn btn-secondary" 
                                            type="button" 
                                            onClick={() => closeModal()} >{t('no')}</button>
                                    &nbsp;
                                    <button className="btn btn-primary" 
                                            type="button" 
                                            onClick={() => openModal.fnAceptar()} >{t('yes')}</button>
                                </FooterModal> 
                            </ContenedorModal>
                        </CentradoModal>
                    </Overlay>
                }
            </>;
            break;
        case 'message':
            varRender = 
                <>
                {
                    <Overlay>
                        <CentradoModal>
                            <ContenedorModal>    
                                <TitleModal>
                                    <FaInfoCircle />
                                </TitleModal>
                                <BodyModal>
                                {openModal.message}
                                </BodyModal>  
                                <FooterModal>                            
                                    <button className="btn btn-primary" 
                                            type="button" 
                                            onClick={() => closeModal()} >{t('ok')}</button>
                                </FooterModal>     
                            </ContenedorModal>
                        </CentradoModal>
                    </Overlay>
                }
            </>;
            break;
        case 'messageOk':
            varRender = 
                <>
                {
                    <Overlay>
                        <CentradoModal>
                            <ContenedorModal>    
                                <TitleModal>
                                    <ColorGreen>
                                        <FaCheckCircle />
                                    </ColorGreen>                                    
                                </TitleModal>
                                <BodyModal>
                                    <CenterText>  
                                        {openModal.message}
                                    </CenterText>  
                                </BodyModal>  
                                <FooterModal>                            
                                    <CenterText>
                                        <button className="btn btn-primary" 
                                                type="button" 
                                                onClick={() => closeModal()} >{t('ok')}</button>
                                    </CenterText>   
                                </FooterModal>     
                            </ContenedorModal>
                        </CentradoModal>
                    </Overlay>
                }
            </>;
            break;
        case 'messageError':
            varRender = 
                <>
                {
                    <Overlay>
                        <CentradoModal>
                            <ContenedorModal>    
                                <TitleModal>
                                    <ColorRed>
                                        <FaTimesCircle />
                                    </ColorRed>                                    
                                </TitleModal>
                                <BodyModal>
                                    <CenterText>  
                                        {openModal.message}
                                    </CenterText>                                    
                                </BodyModal>  
                                <FooterModal>    
                                    <CenterText>
                                        <button className="btn btn-primary" 
                                                type="button" 
                                                onClick={() => closeModal()} >{t('ok')}</button>
                                    </CenterText>                                                            
                                </FooterModal>     
                            </ContenedorModal>
                        </CentradoModal>
                    </Overlay>
                }
            </>;
            break;         
        default:
            varRender = 
                <>
                {
                    <Overlay>
                        <CentradoModal>
                            <ContenedorModal>      
                                <SmallIconModal>
                                    <FaRegClock /> 
                                </SmallIconModal>
                                <CenterText>                                    
                                    {openModal.message}
                                </CenterText>
                            </ContenedorModal>
                        </CentradoModal>
                    </Overlay>
                }
            </>;
            break;  
    }

    // Imprimiendo componente
    return (varRender);
}
export default ModalByMANV;

//
// Estilos del componente
//

const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
`

const CentradoModal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const ContenedorModal = styled.div`
    min-width: 250px;
    max-width: 500px;    
    width: 400px;    
    height: auto;
    background: white;
    color: black;
    padding: 10px;
    margin: 0px;    
    z-index: 10;
    border-radius: 10px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
`

const TitleModal = styled.div`
    width: 100%;    
    height: auto;
    color: gray;
    font-size: 50px;
    text-align: center;    
`

const SmallIconModal = styled.div`
    color: black;
    font-size: 32px;
    text-align: center;
    color: gray;
    padding-top:0px;
    padding-bottom:10px;
`

const ColorRed = styled.div`
    color:red !important;
`

const ColorGreen = styled.div`
    color:green !important;
`

const BodyModal = styled.div`
    width: 100%;    
    height: auto;
    color: black;
    padding-top: 10px;
    padding-bottom:10px;
`

const FooterModal = styled.div`
    width: 100%;    
    height: auto;
    color: black;
    text-align:right;
    padding-top: 10px;
    border-top:1px solid #CFCFCF;
`

const CenterText = styled.div`
    text-align: center;
`