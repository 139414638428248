import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'

const Portal = () => {
    // Predefiniendo variables
    const [config, setConfiG] = useState([]);
    const [nosotros, setNosotros] = useState([]);
    const [productos, setProductos] = useState([]);
    const [servicios, setServicios] = useState([]);

    // Invocador
    useEffect ( () => {
        getValores()
    }, [])

    // Metodos
    const getValores = async () => {
        
        const auxTodos = {
            "ConfiG" : {
              "Name" : "TecnoTrueno",
              "Company" : "TecnoTrueno SAC",
              "Descrip" : "La empresa se destaca como líder en el sector tecnológico por su compromiso con la investigación y desarrollo de soluciones vanguardistas. Nuestro enfoque se centra en la creación de productos y servicios diseñados meticulosamente para fortalecer la eficiencia y el desempeño de diversas organizaciones, como empresas e instituciones. Nos impulsa la visión de ser catalizadores de la excelencia operativa y agentes de cambio que contribuyen a mejorar la vida de las personas de manera significativa.",
              "Descrip2" : "Con una sólida base en la innovación, nuestro equipo multidisciplinario trabaja incansablemente para anticipar las necesidades emergentes del mercado. Nos enorgullece no solo ofrecer soluciones tecnológicas de vanguardia, sino también ser impulsores de un impacto positivo en la sociedad. En última instancia, buscamos trascender los límites convencionales de la tecnología, forjando un futuro donde la excelencia operativa se fusiona armoniosamente con la mejora tangible de la calidad de vida.",
              "Telephone" : "959600899",
              "Email" : "info@tecnotrueno.com",
            },
            "Nosotros" : [
              {
                "title" : "Mision",
                "text" : "Investigar, desarrollar y crear tecnología confiable y útil para personas y organizaciones.",
                "color" : "#007bff",
                "link" : "#",
                "icon" : "fa fa-handshake-o",
              },
              {
                "title" : "Vision",
                "text" : "Crear y mantener los productos y servicios tecnológicos más importantes y útiles del mundo.",
                "color" : "#007bff",
                "link" : "#",
                "icon" : "fa fa-eye",
              },
              {
                "title" : "Valores",
                "text" : "Confianza, innovacion, compromiso, responsabilidad, vocación, pasión, simplicidad y persistencia.",
                "color" : "#007bff",
                "link" : "#",
                "icon" : "fa fa-leaf",
              },
            ],
            "Productos" : [
              {
                "title" : "Simulacro Exámenes",
                "text" : "Sistema de simulacros de exámenes en general para todo tipo de objetivo, laborales, académicos, para tramites de licencias, misceláneos, etc.",
                "color" : "#007bff",
                "link" : "#",
              },
              {
                "title" : "AstroIA",
                "text" : "Servicio de IA para analizar tu vida de acuerdo con la posición de los astros y tus características.",
                "color" : "#007bff",
                "link" : "#",
              },
              {
                "title" : "MicroBingo",
                "text" : "Juego de micro bingos, donde el público en general podrá ingresar a jugar bingos en todo momento, con grupos pequeños de 10, 15 y 20 personas las probabilidades de ganar son más grandes.",
                "color" : "#007bff",
                "link" : "#",
              },
            ],
            "Servicios" : [
              {
                "title" : "Desarrollo de software",
                "text" : "Somos una empresa especializada en el desarrollo de software, aplicando las tecnologías que mejor se adaptan a las necesidades de nuestros clientes.",
                "color" : "#007bff",
                "link" : "#",
              },
              {
                "title" : "Asesoría tecnológica",
                "text" : "Una buena elección y asesoramiento sobre los sistemas y herramientas informáticas que han de dar soporte a una organización es esencial para que ésta se mantenga rentable y eficiente.",
                "color" : "#007bff",
                "link" : "#",
              },
              {
                "title" : "Marketing digital",
                "text" : "Realizamos páginas web, nos encargamos de mejorar su presencia en Internet frente a la competencia mediante técnicas de posicionamiento SEO y SEM.",
                "color" : "#007bff",
                "link" : "#",
              },
              {
                "title" : "Gestión de Sistemas",
                "text" : "Estudiamos las necesidades de los clientes, diseñamos su infraestructura y brindamos soporte y mantenimiento informático de los sistemas. También contamos con servicios informáticos cloud.",
                "color" : "#007bff",
                "link" : "#",
              },
            ]
        };
        
        // Configuracion
        setConfiG(auxTodos.ConfiG);

        // Nosotros
        setNosotros(auxTodos.Nosotros);

        // Productos
        setProductos(auxTodos.Productos);

        // Productos
        setServicios(auxTodos.Servicios);

    }

    return (            
        <div className='container-md justify-content-md-center' id="divInicio">       

            <div className='animated fadeIn'>
                
                <div className="row">
                    <div className="col-sm-12">
                        <br/>
                    </div>
                </div>
                {/* /.row */}

                <div className="row">
                    <div className="col-sm-12">
                        <div id="carouselExampleIndicators" className="carousel slide">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img className="d-block w-100" src="img/banner/banner1.jpg" data-holder-rendered="true" alt="Banner1" style={{ maxHeight: "250px" }} />
                                </div>
                                <div class="carousel-item">
                                    <img className="d-block w-100" src="img/banner/banner2.jpg" data-holder-rendered="true" alt="Banner2" style={{ maxHeight: "250px" }} /> 
                                </div>
                                <div class="carousel-item">
                                    <img className="d-block w-100" src="img/banner/banner3.jpg" data-holder-rendered="true" alt="Banner3" style={{ maxHeight: "250px" }} />
                                </div>
                            </div>

                        </div>
                        {/* /.carousel */}
                    </div>                    
                    {/* /.col */}
                </div>
                {/* /.row */}

                <span id="divNosotros"></span>
                <div className="row" >
                    <div className="col-sm-12">
                    <br/><br/><br/>
                    </div>
                </div>
                {/* /.row */}

                <div className="row" >
                    <div className="col-sm-12">
                        <div className="card shadow-sm">
                            <div className="card-header font-weight-bold">
                                Nosotros
                            </div>
                            <div className="card-body">

                                <div className="jumbotron pnNosotros texto-justificado" >
                                    <div className="container">
                                        
                                        <h3>
                                            <img className="navbar-brand-full" src="img/brand/logo.png" width="30" height="30" alt="TecnoTrueno"/> {config.Company}
                                        </h3>
                                        <p>{config.Descrip}</p>
                                        <p>{config.Descrip2}</p>
                                    </div>
                                </div>

                                <div className="list-group">
                                    {nosotros.map((item,index) => (
                                        <div className="list-group-item list-group-item-action flex-column align-items-start border-none texto-justificado" 
                                             style={(!(index > 0)? { border: "1px solid white"}:{ border: "1px solid white", borderTop: "1px solid #ccc" })} 
                                             key={index} >
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1">
                                                    <i className={item.icon} ></i> {item.title}
                                                </h5>
                                            </div>
                                            <p className="mb-1">{item.text}</p>
                                        </div>
                                    ))}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                {/* /.row */}

                <div className="row">
                    <div className="col-sm-6">
                        <span id="divProductos"></span>
                        <br/><br/><br/>

                        <div className="card shadow-sm">
                            <div className="card-header font-weight-bold">
                                <i className="bi bi-box-seam" ></i> Productos
                            </div>
                            <div className="card-body">

                                <div className="list-group">
                                    {productos.map((item,index) => (
                                        <div className="list-group-item list-group-item-action flex-column align-items-start border-none texto-justificado" 
                                            style={(!(index > 0)? { border: "1px solid white"}:{ border: "1px solid white", borderTop: "1px solid #ccc" })} 
                                            key={index} >
                                           <div className="d-flex w-100 justify-content-between" >
                                               <h5 className="mb-1">
                                                   <i className="fa fa-cube"></i> {item.title}
                                               </h5>
                                           </div>
                                           <p className="mb-1">{item.text}</p>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <span id="divServicios"></span>
                        <br/><br/><br/>

                        <div className="card shadow-sm">
                            <div className="card-header font-weight-bold">
                                <i className="bi bi-cloud-lightning-rain" ></i> Servicios
                            </div>
                            <div className="card-body">

                                <div className="list-group">
                                    {servicios.map((item,index) => (
                                        <div className="list-group-item list-group-item-action flex-column align-items-start border-none texto-justificado" 
                                             style={(!(index > 0)? { border: "1px solid white"}:{ border: "1px solid white", borderTop: "1px solid #ccc" })} 
                                             key={index} >
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1">
                                                    <i className='fa fa-share-alt'></i> {item.title}
                                                </h5>
                                            </div>
                                            <p className="mb-1">{item.text}</p>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* /.row */}

                <span id="divContacto"></span>
                <br/><br/><br/>
                
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card shadow-sm">
                            <div className="card-header font-weight-bold">
                                <i className="bi bi-chat-dots" ></i> Contacto
                            </div>

                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                        <label htmlFor="name">Nombre</label>
                                        <input className="form-control" id="name" type="text" placeholder="Ingresa tu nombre" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                        <label htmlFor="name">Correo Electronico</label>
                                        <input className="form-control" id="name" type="text" placeholder="Ingresa tu correo electronico" />
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /.row--> */}

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="ccmonth">Tipo de consulta</label>
                                            <select className="form-control" id="ccmonth">
                                                <option>Información de productos</option>
                                                <option>Información de servicios</option>
                                                <option>Información de la empresa</option>
                                                <option>Información en general</option>
                                            </select>    
                                        </div>                                        
                                    </div>
                                </div>
                                {/* <!-- /.row--> */}

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="ccmonth">Consulta</label>
                                            <textarea className="form-control" rows="5"></textarea>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /.row--> */}

                                <div className="row">
                                    <div className="col-sm-12">
                                        <button className='btn btn-primary'>Enviar</button>
                                    </div>
                                </div>
                                {/* <!-- /.row--> */}
                            </div>

                        </div>
                    </div>
                </div>
                {/* /.row */}

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                        <label htmlFor="name">Dirección</label>
                                        <p>
                                            Av Garzon 1265 - Jesús María
                                        </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                        <label htmlFor="name">Telefono</label>
                                        <p>
                                            959600899
                                        </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /.row--> */}

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                        <label htmlFor="name">Horario de atención</label>                                        
                                        <p>
                                            Lunes a Sabado de 2pm a 8pm
                                        </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                        <label htmlFor="name">Correo electronico</label>
                                        <p>
                                            info@tecnotrueno.com
                                        </p>                                        
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /.row--> */}

                            </div>

                        </div>
                    </div>
                </div>
                {/* /.row */}

            </div>
        </div>
    )
}

export default Portal