
import React from 'react'

export const HtmlAsidebar = () => {

    let url="#";
    return (    
        <div class="offcanvas offcanvas-end bg-dark text-bg-dark" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div class="offcanvas-header">
                <h5 id="offcanvasRightLabel">
                    <i class="bi bi-person-fill"></i> Cuenta
                </h5>
                <button type="button" class="btn-close text-reset btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>            
            </div>
            <div class="offcanvas-body" >
    
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <h5 class="card-title">Usuario</h5>
                                <p class="card-text">60976</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <hr/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h5 class="card-title">Roles</h5>
                                <p class="card-text">60976</p>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
    )
}
